import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDayEndDateDetails, IDayEndDetails, IDepositSlipByDateRange, ISaveDayEndDetails } from 'src/app/Shared/models/VendorsModel';

@Injectable({
  providedIn: 'root',
})

export class OperationRentalService {
  private baseURL: string;
  constructor(private http: HttpClient) {
    this.baseURL = environment.apiBaseUrl;
  }

  public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  getDailyMarketDays(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetDailyMarketDays?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorById(Id: number): Observable<any> {    
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetVendorInfoById?Id=${Id}`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }
  getAvailableBooths(selectedMarketDays, partId): Observable<any> {
    const url = `${this.baseURL}/Rental/GetAvailableBooths?partId=${partId}`;
    return this.http.post<any>(url, selectedMarketDays, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetAvailableBoothsForMR(SearchOption): Observable<any> {
    const url = `${this.baseURL}/Rental/GetAvailableBoothsForMR`;
    return this.http.post<any>(url, SearchOption, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getDefaultRentalNotes(marketId): Observable<string> {
    return this.http.get(
      `${this.baseURL}/Rental/GetDefaultRentalNotes?marketId=${marketId}`,
      { responseType: 'text' }
    ).pipe(
      map(res => res as string),
      catchError(this.handleError)
    );
  }

  getDefaultMarketId(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/User/GetDefaultMarketId`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getRentalSettings(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetRentalSettings?marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getVendorRentalDays(rentalDays): Observable<any> {
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      const url = `${this.baseURL}/Rental/GetVendorRentalDays`;
      return this.http.post<any>(url, rentalDays, options)
        .pipe(
          map(res => {
            const response = res as any[];
            return response;
          }),
          catchError(this.handleError)
        );
  }

  getVendorRentalWeeks(rentalDays): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetVendorRentalWeeks`;
    return this.http.post<any>(url, rentalDays, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
}

  getBoothrentalCharges(boothRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    if(boothRentalCharges.RentalDate){
      const timezoneOffset = boothRentalCharges.RentalDate.getTimezoneOffset();
      const adjustedDate = new Date(boothRentalCharges.RentalDate.getTime() - (timezoneOffset * 60000));
      boothRentalCharges.RentalDate = adjustedDate.toISOString();
    }
    const url = `${this.baseURL}/Rental/GetBoothrentalCharges`;
    return this.http.post<any>(url, boothRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getVendorBalances(vendorId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetVendorBalances?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getVendorBusinessLicenseSettings(vendorId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetVendorBusinessLicenseSettings?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getOtherRentalItems(marketId,rentalDate = ''): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetOtherRentalItems?marketId=${marketId}&rentalDate=${rentalDate}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getDailyRentals(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetDailyRentals?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getDailyPaymentReceipt(paymentReceiptData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/GetDailyPaymentReceipt`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  combinePdfs(base64Pdfs: string[]): Observable<{ Success: boolean, CombinedPdf: string }> {
    return this.http.post<{ Success: boolean, CombinedPdf: string }>(this.baseURL + '/PaymentReceipt/combine',base64Pdfs);
  }

  generateRentalContract(paymentReceiptData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/GenerateRentalContract`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GeneratePaymentReceipt(paymentReceiptData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/GeneratePaymentReceipt`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  } 

  generateAutopaySummary(batchNo): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/PaymentReceipt/GenerateAutopaySummary?BatchNo=${batchNo}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  } 

  getCustomSettings(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCustomSettings`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  } 

  UploadRentalContractToBlob(rentalContractData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/UploadRentalContractToBlob`;
    return this.http.post<any>(url, rentalContractData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  DisplayQDRPaymentReceipt(paymentReceiptData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/DisplayQDRPaymentReceipt`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  PrintQDRPaymentReceipt(paymentReceiptData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/PrintQDRPaymentReceipt`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }


  getDailyOtherRentals(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetDailyOtherRentals?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  DisplayVoidReceipt(paymentReceiptData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/DisplayVoidReceipt`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  DisplayMarkAbsentee(paymentReceiptData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/DisplayMarkAbsenteeReceipt`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
  
  DisplayDeleteRentalReceipt(paymentReceiptData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/DisplayDeleteRentalReceipt`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getCreditCardTypes(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCreditCardTypes`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getOtherItemsRentalCharges(otherRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetOtherItemsRentalCharges`;
    return this.http.post<any>(url, otherRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveDailyRental(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveDailyRentalTransactions`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SavePaymentRental(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SavePaymentTransactions`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveDeposit(depositData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveDepositTransactions`;
    return this.http.post<any>(url, depositData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getProductCategories(marketId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetProductCategories?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  generateTempRecordNumber(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GenerateTempRecordNumber`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  insertDataToTemp(tempData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/InsertDataToTemp`;
    if (tempData.length > 0) {
      for (var item of tempData) {
        if (item.StartDate) {
          const timezoneOffset = item.StartDate.getTimezoneOffset();
          const adjustedDate = new Date(item.StartDate.getTime() - (timezoneOffset * 60000));
          item.StartDate = adjustedDate.toISOString();
        }
      }
    }
    return this.http.post<any>(url, tempData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteFromTemp(tempData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/DeleteFromTemp`;
    
    if(tempData.StartDate != undefined){
      const timezoneOffset = tempData.StartDate.getTimezoneOffset();
          const adjustedDate = new Date(tempData.StartDate.getTime() - (timezoneOffset * 60000));
          tempData.StartDate =  adjustedDate.toISOString();
      }
    return this.http.post<any>(url, tempData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getAllBoothsForDailyRentalTypeLayout(partId, selectedMarketDays): Observable<any> {
    const url = `${this.baseURL}/Rental/GetAllBoothsForDailyRentalTypeLayout?partId=` + partId
    return this.http.post<any>(url, selectedMarketDays, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  checkAbsenteeVendorForDate(boothNumber, selectedMarketDays): Observable<any> {
    const url = `${this.baseURL}/Rental/CheckAbsenteeVendorForDate?BoothNumber=` + boothNumber
    return this.http.post<any>(url, selectedMarketDays, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getWeeklyMarketDays(): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetWeeklyMarketDays`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  getMarketDaysSettings(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetMarketDaysSettings`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;;
        }),
        catchError(this.handleError)
      );
  }

  GetMarketDaysSettingsForMarket(id): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetMarketDaysSettingsForMarket?Id=` + id,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;;
        }),
        catchError(this.handleError)
      );
  }

  getAvailableBoothsForWeekly(selectedDates): Observable<any> {
    const url = `${this.baseURL}/Rental/GetAvailableBoothsForWeekly`;
    return this.http.post<any>(url, selectedDates, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getBoothRentalWeeklyCharges(weeklyRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBoothRentalWeeklyCharges`;
    return this.http.post<any>(url, weeklyRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getALLBoothsForLayoutALLRentalTypes(rentalTypesDatesModel): Observable<any> {
    const url = `${this.baseURL}/Rental/GetALLBoothsForLayoutALLRentalTypes`
    return this.http.post<any>(url, rentalTypesDatesModel, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  saveWeeklyRental(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveWeeklyRentalTransactions`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getWeeklyBookings(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetWeeklyBookings?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getWeeklyOtherRentals(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetWeeklyOtherRentals?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getAvailableBoothsForCombinedWeekly(selectedDates): Observable<any> {
    const url = `${this.baseURL}/Rental/GetAvailableBoothsForCombinedWeekly`;
    return this.http.post<any>(url, selectedDates, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getBoothRentalCombinedWeeklyCharges(cweeklyRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBoothRentalCombinedWeeklyCharges`;
    return this.http.post<any>(url, cweeklyRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getBoothRentalCombinedMonthlyCharges(cmonthlyRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBoothRentalCombinedMonthlyCharges`;
    return this.http.post<any>(url, cmonthlyRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getRecordFromTemp(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetRecordFromTemp`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getMonthlyMarketDays(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetMonthlyMarketDays`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getAvailableBoothsForMonthly(selectedDates): Observable<any> {
    const url = `${this.baseURL}/Rental/GetAvailableBoothsForMonthly`;
    return this.http.post<any>(url, selectedDates, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getAvailableBoothsForCombinedMonthly(selectedDates): Observable<any> {
    const url = `${this.baseURL}/Rental/GetAvailableBoothsForCombinedMonthly`;
    return this.http.post<any>(url, selectedDates, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  saveCombinedWeeklyRentalTransactions(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveCombinedWeeklyRentalTransactions`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getCombinedWeeklyBookings(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCombinedWeeklyBookings?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getCombinedWeeklyOtherRentals(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCombinedWeeklyOtherRentals?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  GetBoothRentalMontlyCharges(weeklyRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBoothRentalMontlyCharges`;
    return this.http.post<any>(url, weeklyRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  saveMonthlyRental(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveMonthlyRentalTransactions`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  CheckMinimumUnusedCodeCount(RentalType): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/CheckMinimumUnusedCodeCount?RentalType=` + RentalType,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  updateWIFIAccessCodeForRental(wifiData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/UpdateWIFIAccessCodeForRental`;
    return this.http.post<any>(url, wifiData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  saveCombinedMonthlyRental(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveCombinedMonthlyRentalTransactions`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteMonthlyReservation(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/DeleteMonthlyReservation`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteCombinedMonthlyReservation(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/DeleteCombinedMonthlyReservation`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteCombinedWeeklyOtherItemReservation(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/DeleteCombinedWeeklyOtherItemReservation`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteCombinedMonthlyOtherItemReservation(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/DeleteCombinedMonthlyOtherItemReservation`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteMonthlyOtherItemReservation(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/deleteMonthlyOtherItemReservation`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteCombinedWeeklyReservation(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/DeleteCombinedWeeklyReservation`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getMonthlyOtherRentals(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetMonthlyOtherRentals?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getMonthlyBookings(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetMonthlyBookings?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getCombinedMonthlyBookings(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCombinedMonthlyBookings?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getCombinedMonthlyOtherRentals(vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCombinedMonthlyOtherRentals?vendorId=` + vendorId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  SaveQuickDailyRental(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveQuickDailyRentalTransactions`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveQuickDailyOtherRental(rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveQuickDailyOtherRentalTransactions`;
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetRentalReservationForBooth(Reservation): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetReservations`; 
    
    return this.http.post<any>(url, Reservation, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      // this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }

  GetDepositSlipByDateRange(DepositSlip: IDepositSlipByDateRange): Observable<any> {              
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetDepositSlipByDateRange`;
    return this.http.post<any>(url, DepositSlip, options)
      .pipe(
        map(res => {
          const response = res as any[];          
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetDepositSlipByRecieptRange(DepositSlip: IDepositSlipByDateRange): Observable<any> {       
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetDepositSlipByRecieptRange`;
    return this.http.post<any>(url, DepositSlip, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetUserDetails(): Observable<any> {    
    return this.http.get<any>(
      `${this.baseURL}/AdminUser/GetAllUserList`,

      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }  

  GetFirstPaymentOrDayEndDate(marketId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/DayEnd/GetFirstPaymentOrDayEndDate`, 
      {
        observe: 'response',
        params: { marketId: marketId.toString() }  
      }
    ).pipe(
      map(res => {
        const response = res as any;
        return response;
      }),
      catchError(this.handleError)
    );
  }



  GetDepositSlipByDateRangeAndVendorId(DepositSlip: IDepositSlipByDateRange): Observable<any> {    
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetDepositSlipByDateRangeAndVendorId`;
    return this.http.post<any>(url, DepositSlip, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetDepositSlipByRecieptRangeAndVendorId(DepositSlip: IDepositSlipByDateRange): Observable<any> {
    
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetDepositSlipByRecieptRangeAndVendorId`;
    return this.http.post<any>(url, DepositSlip, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  MakeAvailable(AvailableReservation): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/MakeAvailable`;
    return this.http.post<any>(url, AvailableReservation, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetBookingsForToday(Reservation): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBookingsForToday`; 
    
    return this.http.post<any>(url, Reservation, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  MakeAvailableForToday(AvailableReservation): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/MakeAvailableForToday`;
    return this.http.post<any>(url, AvailableReservation, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  MakeOrUndoAvailableForToday(makeOrUndoParm): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/MakeOrUndoAvailableForToday`;
    return this.http.post<any>(url, makeOrUndoParm, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetDepositSlipReceipt(DepositSlip: IDepositSlipByDateRange): Observable<any> {           
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/GetDepositSlipReceipt`;
    return this.http.post<any>(url, DepositSlip, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetDepositSlipEndOfDayReceipt(DepositSlipDayEnd: IDayEndDetails): Observable<any> { 
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/GetDepositSlipEndOfDayReceipt`;
    return this.http.post<any>(url, DepositSlipDayEnd, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetDayEndDetails(DepositSlip: IDayEndDetails): Observable<any> {    
   const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetDayEndDetails`;
    return this.http.post<any>(url, DepositSlip, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  saveDayEndDetails(DayEndDetails: ISaveDayEndDetails): Observable<any> {   
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/DayEnd/saveDayEndDetails`;
    return this.http.post<any>(url, DayEndDetails, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  DeleteDayEndDetails(DayEndDetails: IDayEndDateDetails): Observable<any> {  
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/DayEnd/DeleteDayEndDetails`;  
    return this.http.post<any>(url, DayEndDetails, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }  

  GetDayEndDates(marketId: number): Observable<any> {    
    return this.http.get<any>(
      `${this.baseURL}/DayEnd/GetDayEndDates`,  
      { 
        observe: 'response',
        params: { marketId: marketId.toString() } 
      })
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
}

  GetAdditionalAndExcludedMarketDays(): Observable<any> {    
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetAdditionalAndExcludedMarketDays`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const response = res.body as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetAdditionalAndExcludedMarketDaysByMarketId(MarketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetAdditionalAndExcludedMarketDaysByMarketId?MarketId=${MarketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const response = res.body as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  ComposeEmails(emailData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Email/ComposeEmails`;
    return this.http.post<any>(url, emailData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  checkRentalExits(rentalDateDetails): Observable<boolean> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/CheckRentalExits`;
    return this.http.post<any>(url, rentalDateDetails, options)
    .pipe(
      map(res => {
        const response = res as boolean;
        return response;
      }),
      catchError(this.handleError)
    );
  }

  GetRentalPrintReceiptNoOfCopies(rentalType): Observable<string> {
    return this.http.get(
      `${this.baseURL}/PaymentReceipt/GetRentalPrintReceiptNoOfCopies?rentalType=${rentalType}`,
      { responseType: 'text' }
    ).pipe(
      map(res => res as string),
      catchError(this.handleError)
    );
  }

  generateContractForAccount(accountReceiptId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/PaymentReceipt/GenerateContractForAccount?accountReceiptId=${accountReceiptId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  displayProformaInvoice(proformaInvoiceIds: number[]): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/DisplayProformaInvoice`;
     return this.http.post<any>(url, proformaInvoiceIds, options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  UpdateVendorResaleInfo(vendorResaleInfo): Observable<boolean> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/UpdateVendorResaleInfo`;
    return this.http.post<any>(url, vendorResaleInfo, options)
    .pipe(
      map(res => {
        const response = res as boolean;
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getBoothStatus(boothStatusInput): Observable<any> {
    const url = `${this.baseURL}/Rental/GetBoothStatus`;
    return this.http.post<any>(url, boothStatusInput, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetDailyMarketDaysForMR(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetDailyMarketDaysForMR?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  GetSelectedMonthEndDate(yearMonth): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetSelectedMonthEndDate?yearMonth=` + yearMonth,
      { observe: 'response' })
      .pipe(
        map(res => {
          const monthEndDate = res.body as any;
          return monthEndDate;
        }),
        catchError(this.handleError)
      );
  }
  SaveCloseOutData(closeOutBooths): Observable<any> {
    const url = `${this.baseURL}/Rental/SaveCloseOutData`;
    return this.http.post<any>(url, closeOutBooths, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  public ClosedOutBoothCount(fromDate: string, toDate: string,partId): Observable<number> {
    const url = `${this.baseURL}/Rental/ClosedOutBoothCount`; 

    const Dates = {
      fromDate: fromDate,
      toDate: toDate,
      partId: parseInt(partId)
    };
    return this.http.post<any>(url, Dates, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  SaveReleaseBoothData(ReleaseBooths): Observable<any> {
    const url = `${this.baseURL}/Rental/SaveReleaseBoothData`;
    return this.http.post<any>(url, ReleaseBooths, this.options)
      .pipe(
        map(res => {
          const response = res as any;

          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetDailyMarketDaysForMonth(YearMonth): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetDailyMarketDaysForMonth?YearMonth=` + YearMonth,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  LoadBoothStatus(BoothStatusParms): Observable<any> {
    const url = `${this.baseURL}/Rental/LoadBoothStatus`;
    return this.http.post<any>(url, BoothStatusParms, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  LoadBoothStatusMR(BoothStatusParms): Observable<any> {
    const url = `${this.baseURL}/Rental/LoadBoothStatusMR`;
    return this.http.post<any>(url, BoothStatusParms, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }
  MarkAbsenteeMR(BoothStatusParms): Observable<any> {
    const url = `${this.baseURL}/Rental/MarkAbsenteeMR`;
    return this.http.post<any>(url, BoothStatusParms, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }
  PaymentReceiptforMR(paymentReceiptData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/PaymentReceiptforMR`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
  GetRentalCharge(ChargeParms): Observable<any> {
    const url = `${this.baseURL}/Rental/GetRentalCharge`;
    return this.http.post<any>(url, ChargeParms, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }
  //date conversion
  getDateFromDateTime(date){
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
 
  DisplayBoothStatusReport(boothstatusParm: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/PaymentReceipt/DisplayBoothStatusReport`;
    return this.http.post<any>(url, boothstatusParm, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  RefundAbsenteefeeOnHold(Searchoption: any): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/RefundAbsenteefeeOnHold`;
    return this.http.post<any>(url, Searchoption, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
}
